import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';
import { useAppStateAPI } from '../common/AppStateAPI';
import * as Utilities from '../common/Utilities';
import * as ServerAPI from '../common/ServerAPI';

import { Helmet } from 'react-helmet';

const Contact: React.FC = () => {

    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const submitEnquiry = () => {
        if (!contactName) {
            showToastMsg('error', 'Please enter your name');
        } else if (!contactEmail) {
            showToastMsg('error', 'Please enter email');
        } else if (!Utilities.isValidEmail(contactEmail)) {
            showToastMsg('error', 'Please enter valid email');
        } else if (!subject) {
            showToastMsg('error', 'Please enter the Subject');
        } else if (!message) {
            showToastMsg('error', 'Please enter the message');
        } else {
            addProcessingRequests();
            ServerAPI.sendContactEnquiry(contactName, contactEmail, subject, message).then((response) => {
                if (response?.success) {
                    showToastMsg('info', response?.message);
                    setContactName('');
                    setContactEmail('');
                    setSubject('');
                    setMessage('');
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Contact</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/contact' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Contact' />
                <meta property='og:description' content='VENETZ | Contact' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">Contact</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Contact</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="contact-section pt-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <h3 className="sec-title">VENETZ READY TO INSTALL MOSQUITO NET SOLUTIONS</h3>
                                    <div className="info-contact">
                                        <h5>Address: <p> 110, Sengunthar Nagar, <br></br>Vengamedu, Karur- 639 006, <br></br>Tamil Nadu, India.</p></h5>
                                        <h5>Phone: <p> +91 95855 20356</p></h5>
                                        <h5>Email: <p> sales@venetz.in</p></h5>
                                        <h5>Social:
                                            <IonRouterLink routerLink="https://www.facebook.com/Venetz-101495215003250/" className='social_icon'><i className="twi-facebook"></i></IonRouterLink>
                                            <IonRouterLink routerLink="https://www.instagram.com/venetzindia/" className='social_icon'><i className="twi-instagram"></i></IonRouterLink>
                                            <IonRouterLink routerLink="https://api.whatsapp.com/send?phone=919585520356" className='social_icon'><i className="twi-whatsapp"></i></IonRouterLink>
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-5">
                                    <div className="contact-thumb">
                                        <img src="assets/images/contact.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="goru_map grayscale">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7833.629585924715!2d78.080395!3d10.977348!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baa2fb436b23fa7%3A0xdeab0e0f95ad12bf!2sVenetz%20-%20Ready%20to%20install%20mosquito%20net%20solutions!5e0!3m2!1sen!2sin!4v1690908334435!5m2!1sen!2sin"></iframe>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12 text-center">
                                            <h2 className="sec-title">Get In Touch With Us</h2>
                                            <p className="sec-desc"></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="contact-form text-center">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <input type="text" className="required" placeholder="Enter your name" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <input type="email" className="required" placeholder="Your Email" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <input type="text" className="required" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <textarea name="con_message" className="required" placeholder="Your message here" value={message} onChange={(e) => setMessage(e.target.value)} />
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <button type="button" className="goru-btn" onClick={submitEnquiry}>Submit</button>
                                                        <img src="assets/images/ajax.gif" alt="" className="goru_loader" />
                                                        <div className="goru_con_message"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default Contact;
