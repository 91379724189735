import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MyAccountLeftSidebar from './MyAccountLeftSidebar';
import { PageLinks } from '../common/Constants';
import { RouteComponentProps } from 'react-router';
import * as ServerAPI from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';
import { APIData } from '../common/DataTypes';
import OrderDetailCard from '../components/orders/OrderDetailCard';

import { Helmet } from 'react-helmet';

const OrderDetails: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {

    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [orderID, setOrderID] = useState(0);

    const [orderDetail, setOrderDetail] = useState<APIData.OrderDetail>();

    useEffect(() => {
        setOrderID(parseInt(match.params.id));
    }, [match]);

    useEffect(() => {
        fetchOrderDetail();
    }, [orderID]);

    const fetchOrderDetail = () => {
        if (orderID) {
            addProcessingRequests();
            ServerAPI.getOrderDetail(orderID).then((response) => {
                if (response.orders.length > 0) {
                    const order: APIData.OrderDetail = response.orders[0];
                    setOrderDetail(order);
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Order Details</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/order-details' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Order Details' />
                <meta property='og:description' content='VENETZ | Order Details' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">My Account</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / My Account / Order Details</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="blog-section pt-5">
                        <div className="container-fluid">
                            <div className="row">
                                <MyAccountLeftSidebar />
                                <div className="main-content col-xl-9 col-lg-8 col-md-12 col-sm-12 my-account">
                                    <div className="post-info">
                                        <h2 className="post-title"><a href="#">Order Details</a></h2>
                                    </div>
                                    {orderDetail !== undefined && (
                                        <div className="white-card">
                                            <div className="row order-response">
                                                <OrderDetailCard orderDetail={orderDetail} />
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default OrderDetails;
