
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as Utilities from '../../common/Utilities';
import * as ServerAPI from '../../common/ServerAPI';
import { useAppStateAPI } from '../../common/AppStateAPI';
import { APIData } from '../../common/DataTypes';

const modalStyle = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    position: 'absolute',
    top: '5%',
    left: '25%',
    right: '25%',
    overflow: 'auto',
    width: '50%',
    height: '90%',
    display: 'block'
};

const AddressEntry: React.FC<{ showModal: boolean, setShowModal: (value: boolean | (() => boolean)) => void, successCallBack: () => void, addressID?: number }> = ({ showModal, setShowModal, successCallBack, addressID }) => {

    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [countries, setCountries] = useState<APIData.Country[]>([]);
    const [states, setStates] = useState<APIData.State[]>([]);
    const [phone, setPhone] = useState('');
    const [fullName, setFullName] = useState('');
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [landmark, setLandmark] = useState('');
    const [city, setCity] = useState('');
    const [stateID, setStateID] = useState(0);
    const [countryID, setCountryID] = useState(0);
    const [postalCode, setPostalCode] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [remarks, setRemarks] = useState('');
    const [gstin, setGSTIN] = useState('');

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        fetchStates();
    }, [countryID]);

    useEffect(() => {
        fetchAddress();
    }, [addressID]);

    const fetchCountries = () => {
        addProcessingRequests();
        ServerAPI.getCountries().then((response) => {
            if (response?.success) {
                setCountries(response.countries);
            } else if (response?.message) {
                showToastMsg('error', response?.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const fetchStates = () => {
        if (countryID) {
            addProcessingRequests();
            ServerAPI.getStates(countryID).then((response) => {
                if (response?.success) {
                    setStates(response.states);
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setStates([]);
        }
    }

    const fetchAddress = () => {
        if (addressID) {
            addProcessingRequests();
            ServerAPI.getCustomerAddress(addressID).then((response) => {
                if (response?.success && response.customer_addresses && response.customer_addresses.length > 0) {
                    const address: APIData.Address = response.customer_addresses[0];
                    setFullName(address.customer_address_full_name);
                    setPhone(address.customer_address_phone);
                    setLine1(address.customer_address_line1);
                    setLine2(address.customer_address_line2);
                    setLandmark(address.customer_address_landmark);
                    setCity(address.customer_address_city);
                    setStateID(address.customer_address_state_id);
                    setCountryID(address.country_id);
                    setPostalCode(address.customer_address_postal_code);
                    setCompanyName(address.customer_address_company_name);
                    setGSTIN(address.customer_address_gstin);
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        } else {
            setFullName('');
            setPhone('');
            setLine1('');
            setLine2('');
            setLandmark('');
            setCity('');
            setStateID(0);
            setCountryID(0);
            setPostalCode('');
            setCompanyName('');
            setGSTIN('');
        }
    }

    const isAddressValid = () => {
        if (!phone) {
            showToastMsg('error', 'Please enter phone number');
        } else if (!Utilities.isValidIndianMobileNumber(phone)) {
            showToastMsg('error', 'Please enter a valid phone number');
        } else if (!fullName) {
            showToastMsg('error', 'Please enter full name');
        } else if (!line1) {
            showToastMsg('error', 'Please enter address line1');
        } else if (!city) {
            showToastMsg('error', 'Please enter city');
        } else if (!countryID) {
            showToastMsg('error', 'Please select country');
        } else if (!stateID) {
            showToastMsg('error', 'Please select state');
        } else if (!postalCode) {
            showToastMsg('error', 'Please enter pincode');
        } else if (!Utilities.isValidIndianPincode(postalCode)) {
            showToastMsg('error', 'Please enter a valid pincode');
        } else {
            return true;
        }
        return false;
    }

    const addressUpdate = () => {
        if (isAddressValid()) {
            if (addressID) {
                addProcessingRequests();
                ServerAPI.updateCustomerAddress(addressID, fullName, phone, line1, line2, landmark, city, stateID, postalCode, companyName, remarks, gstin).then((response) => {
                    if (response?.success) {
                        showToastMsg('info', response?.message);
                        successCallBack();
                        setShowModal(false);
                    } else if (response?.message) {
                        showToastMsg('error', response?.message);
                    } else {
                        showToastMsg('error', 'Error occurred while processing the request');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            } else {
                addProcessingRequests();
                ServerAPI.addCustomerAddress(fullName, phone, line1, line2, landmark, city, stateID, postalCode, companyName, remarks, gstin).then((response) => {
                    if (response?.success) {
                        showToastMsg('info', response?.message);
                        successCallBack();
                        setShowModal(false);
                    } else if (response?.message) {
                        showToastMsg('error', response?.message);
                    } else {
                        showToastMsg('error', 'Error occurred while processing the request');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
        }
    }

    return (
        <div>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {addressID ? 'Update' : 'Add'} Address
                    </Typography>
                    <div className="row checkout_add_address">
                        <p className="col-lg-6">
                            <label>Full Name</label>
                            <input placeholder="" type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                        </p>
                        <p className="col-lg-6">
                            <label>Phone Number</label>
                            <input placeholder="" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </p>
                        <p className="col-lg-12">
                            <label>Address Line 1</label>
                            <input placeholder="" type="text" value={line1} onChange={(e) => setLine1(e.target.value)} />
                        </p>
                        <p className="col-lg-12">
                            <label>Address Line 2</label>
                            <input placeholder="" type="text" value={line2} onChange={(e) => setLine2(e.target.value)} />
                        </p>
                        <p className="col-lg-6">
                            <label>Landmark</label>
                            <input placeholder="" type="text" value={landmark} onChange={(e) => setLandmark(e.target.value)} />
                        </p>
                        <p className="col-lg-6">
                            <label>City</label>
                            <input placeholder="" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                        </p>
                        <div className="col-lg-6">
                            <div className="billing-countries">
                                <label>Country</label>
                                <select className="country_to_state country_select " value={countryID} onChange={(e) => setCountryID(parseInt(e.target.value))}>
                                    <option value={0}>Select</option>
                                    {countries.map((country, index) => (
                                        <option value={country.country_id} key={index}>{country.country_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="billing-countries">
                                <label>State</label>
                                <select className="country_to_state country_select " value={stateID} onChange={(e) => setStateID(parseInt(e.target.value))}>
                                    <option value={0}>Select</option>
                                    {states.map((state, index) => (
                                        <option value={state.state_id} key={index}>{state.state_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <p className="col-lg-6">
                            <label>Pincode</label>
                            <input placeholder="" name="postcode" type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                        </p>
                        <p className="col-lg-6">
                            <label>Company Name</label>
                            <input placeholder="" name="phone" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                        </p>
                        <p className="col-lg-6">
                            <label>GSTIN</label>
                            <input placeholder="" name="billing_email" type="text" value={gstin} onChange={(e) => setGSTIN(e.target.value)} />
                        </p>
                    </div>
                    <div className="place-order">
                        <div className='checkout_save_btn'>
                            <button type="button" className="button" onClick={addressUpdate}>Save</button>
                        </div>
                        <div className='checkout_save_btn checkout_close_btn'>
                            <button type="button" className="button" onClick={() => setShowModal(false)}>Close</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default AddressEntry;