import { IonRouterLink } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAppStateAPI } from '../../common/AppStateAPI';
import * as Utilities from '../../common/Utilities';
import * as ServerAPI from '../../common/ServerAPI';
import { PageLinks } from '../../common/Constants';

const LoginCard: React.FC = () => {

    const location = useLocation();
    const history = useHistory();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests, setCustomerLogin, loginRedirectLink, setCustomerLoginRedirectLink } = useAppStateAPI();
    const [loginUserName, setLoginUserName] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [noRedirect, setNoRedirect] = useState(false);

    useEffect(() => {
        if (location.pathname === PageLinks.CHECKOUT) {
            setNoRedirect(false);
        }
    }, [location.pathname])

    const loginCustomer = () => {
        if (!loginUserName) {
            showToastMsg('error', 'Please enter your mobile number or email');
        } else if (!loginPassword) {
            showToastMsg('error', 'Please enter your password');
        } else {
            // addProcessingRequests();
            // ServerAPI.customerLogin(loginUserName, loginPassword).then((response) => {
            //     if (response?.success) {
            //         showToastMsg('info', response?.message);
            //         Utilities.persistCustomerLogin(response.customer_id, response.customer_api_key);
            //         setCustomerLogin(true);
            //         if (!noRedirect) {
            //             if (loginRedirectLink) {
            //                 history.replace(loginRedirectLink);
            //                 setCustomerLoginRedirectLink('');
            //             } else if (history.length > 0) {
            //                 history.goBack();
            //             } else {
            //                 history.replace(PageLinks.HOME);
            //             }
            //         }
            //     } else if (response?.message) {
            //         showToastMsg('error', response?.message);
            //     } else {
            //         showToastMsg('error', 'Error occurred while processing the request');
            //     }
            // }).finally(() => {
            //     reduceProcessingRequests();
            // });
        }
    }

    return (
        <div className="col-lg-6 col-md-6 mobile_mt-5">
            <h3 className="sec-title">Login your account</h3>
            <div className="login-form">
                <input type="text" name="name" placeholder="Mobile/Email" value={loginUserName} onChange={(e) => setLoginUserName(e.target.value)} />
                <input type="password" name="password" placeholder="Your Password" value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} />
                {/* <div className="keep-log-regi">
                    <input type="radio" id="login" name="login" value="keep-login" />
                    <label htmlFor="login">Keep me logged in</label>
                </div> */}
                <IonRouterLink routerLink={PageLinks.FORGOT_PASSWORD} className='forgot_password'>Forgot your password?</IonRouterLink>
                <button type="button" className='submit-button' onClick={loginCustomer}>Login</button>
            </div>
        </div>
    )
}

export default LoginCard;