import { IonContent, IonHeader, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { PageLinks } from '../common/Constants';
import { useAppStateAPI } from '../common/AppStateAPI';
import * as Utilities from '../common/Utilities';
import * as ServerAPI from '../common/ServerAPI';
import { useHistory, useLocation } from 'react-router';
import OTPInput from '../components/login/OTPInput';

import { Helmet } from 'react-helmet';

const Login: React.FC = () => {

    const history = useHistory();
    const location = useLocation();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests, setCustomerLogin, setCustomerLoginRedirectLink, loginRedirectLink } = useAppStateAPI();
    const [step, setStep] = useState<'login' | 'login_otp' | 'register_otp'>('login');

    const [loginMobile, setLoginMobile] = useState('');
    const [otp, setOTP] = useState('');
    const [registerFirstName, setRegisterFirstName] = useState('');
    const [registerLastName, setRegisterLastName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [termsAgreed, setTermsAgreed] = useState(false);

    const loginSendOTP = () => {
        if (!loginMobile) {
            showToastMsg('error', 'Please enter your mobile number');
        } else if (!Utilities.isValidIndianMobileNumber(loginMobile)) {
            showToastMsg('error', 'Please enter a valid phone number');
        } else {
            addProcessingRequests();
            ServerAPI.customerLoginSendOTP(loginMobile).then((response) => {
                if (response?.success) {
                    showToastMsg('info', response?.message);
                    if (response.otp) {
                        setOTP(response.otp);
                    }
                    if (response.customer_not_registered) {
                        setStep('register_otp');
                    } else {
                        setStep('login_otp');
                    }
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const loginVerifyOTP = () => {
        if (!loginMobile) {
            showToastMsg('error', 'Please enter your mobile number');
        } else {
            addProcessingRequests();
            ServerAPI.customerLoginVerifyOTP(loginMobile, otp).then((response) => {
                if (response?.success) {
                    showToastMsg('info', response?.message);
                    Utilities.persistCustomerLogin(response.customer_id, response.customer_api_key);
                    setCustomerLogin(true);
                    if (loginRedirectLink) {
                        history.replace(loginRedirectLink);
                        setCustomerLoginRedirectLink('');
                    } else if (history.length > 0) {
                        history.goBack();
                    } else {
                        history.replace(PageLinks.HOME);
                    }
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const registerCustomer = () => {
        if (!registerFirstName) {
            showToastMsg('error', 'Please enter first name');
        } else if (!registerLastName) {
            showToastMsg('error', 'Please enter last name');
        } else if (!registerEmail) {
            showToastMsg('error', 'Please enter email');
        } else if (!Utilities.isValidEmail(registerEmail)) {
            showToastMsg('error', 'Please enter valid email');
        } else if (!termsAgreed) {
            showToastMsg('error', 'Please Agree the Terms & Conditions');
        } else {
            addProcessingRequests();
            ServerAPI.customerRegister(registerFirstName, registerLastName, registerEmail, loginMobile, otp).then((response) => {
                if (response?.success) {
                    showToastMsg('info', response?.message);
                    Utilities.persistCustomerLogin(response.customer_id, response.customer_api_key);
                    setCustomerLogin(true);
                    if (loginRedirectLink) {
                        history.replace(loginRedirectLink);
                        setCustomerLoginRedirectLink('');
                    } else if (history.length > 0) {
                        history.goBack();
                    } else {
                        history.replace(PageLinks.HOME);
                    }
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    useEffect(() => {
        resetStates();
    }, [location.pathname]);

    const resetStates = () => {
        setStep('login')
        setLoginMobile('');
        setOTP('');
        setRegisterFirstName('');
        setRegisterLastName('');
        setRegisterEmail('');
        setTermsAgreed(false);
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Login</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/authentication' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Login' />
                <meta property='og:description' content='VENETZ | Login' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    {/* <section className="page-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <span className="round-shape"></span>
                                <h2 className="banner-title">Login</h2>
                                <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Login</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="login-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 mobile_mt-5">
                                <h3 className="sec-title">Login your account</h3>
                                <div className="login-form">
                                    <input type="text" name="name" placeholder="Mobile/Email" value={loginUserName} onChange={(e) => setLoginUserName(e.target.value)} />
                                    <input type="password" name="password" placeholder="Your Password" value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} />
                                    <IonRouterLink routerLink={PageLinks.FORGOT_PASSWORD} className='forgot_password'>Forgot your password?</IonRouterLink>
                                    <button type="button" className='submit-button' onClick={loginCustomer}>Login</button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 mobile_mt-5">
                                <h3 className="sec-title">Register account now</h3>
                                <div className="register-form">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="text" name="full-name" placeholder="First Name" value={registerFirstName} onChange={(e) => setRegisterFirstName(e.target.value)} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="text" name="name" placeholder="Last Name" value={registerLastName} onChange={(e) => setRegisterLastName(e.target.value)} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="number" name="phone" placeholder="Phone" value={registerPhone} onChange={(e) => setRegisterPhone(e.target.value)} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="email" name="email" placeholder="Email" value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="password" name="password" placeholder="Password" value={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} />
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="password" name="retype-password" placeholder="Re Password" value={registerReTypePassword} onChange={(e) => setRegisterReTypePassword(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="keep-log-regi">
                                        <input type="radio" id="register" name="register" value="keep-register" checked={termsAgreed} onClick={() => setTermsAgreed(!termsAgreed)} />
                                        <label htmlFor="register">
                                            I accept the terms and conditions, including the Privacy Policy
                                        </label>
                                    </div>
                                    <button type="button" className='submit-button' onClick={registerCustomer}>Register</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                    <section className="login-section p-5">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 mar-lef-auto mobile_mt-5">
                                    {step === 'login' && (
                                        <>
                                            <h3 className="sec-title text-center p-3">Login</h3>
                                            <div className="register-form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="col-lg-9 mar-lef-auto">
                                                            <input type="text" placeholder="Mobile" value={loginMobile} onChange={(e) => setLoginMobile(e.target.value)} />
                                                        </div>
                                                        <button type="button" className='submit-button' onClick={loginSendOTP}>Send OTP</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {step === 'login_otp' && (
                                        <>
                                            <h3 className="sec-title text-center p-3">Login with OTP</h3>
                                            <div className="register-form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        {/* <div className="inputs_container">
                                                        <div id="inputs" className="inputs">
                                                            <label>Enter OTP</label>
                                                            <input className="input" type="text"
                                                                inputMode="numeric" maxLength={1} />
                                                            <input className="input" type="text"
                                                                inputMode="numeric" maxLength={1} />
                                                            <input className="input" type="text"
                                                                inputMode="numeric" maxLength={1} />
                                                            <input className="input" type="text"
                                                                inputMode="numeric" maxLength={1} />
                                                        </div>
                                                    </div> */}
                                                        <OTPInput otp={otp} setOTP={setOTP} otpLength={6} />
                                                        <button type="button" className='submit-button' onClick={loginVerifyOTP}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {step === 'register_otp' && (
                                        <>
                                            <h3 className="sec-title text-center">Register with OTP</h3>
                                            <div className="register-form">
                                                <div className="row">
                                                    <div className="col-lg-9 mar-lef-auto">
                                                        <OTPInput otp={otp} setOTP={setOTP} otpLength={6} />
                                                    </div>
                                                    <div className="col-lg-9 mar-lef-auto">
                                                        <input type="text" placeholder="First Name" value={registerFirstName} onChange={(e) => setRegisterFirstName(e.target.value)} />
                                                    </div>
                                                    <div className="col-lg-9 mar-lef-auto">
                                                        <input type="text" placeholder="Last Name" value={registerLastName} onChange={(e) => setRegisterLastName(e.target.value)} />
                                                    </div>
                                                    {/* <div className="col-lg-9 mar-lef-auto">
                                                    <input type="number" placeholder="Phone" value={registerPhone} onChange={(e) => setRegisterPhone(e.target.value)} />
                                                </div> */}
                                                    <div className="col-lg-9 mar-lef-auto">
                                                        <input type="email" placeholder="Email" value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="keep-log-regi">
                                                    <input type="radio" id="register" name="register" value="keep-register" checked={termsAgreed} onClick={() => setTermsAgreed(!termsAgreed)} />
                                                    <label htmlFor="register">
                                                        I accept the terms and conditions, including the Privacy Policy
                                                    </label>
                                                </div>
                                                <button type="button" className='submit-button' onClick={registerCustomer}>Register</button>
                                            </div>
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="login-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 mar-lef-auto mobile_mt-5">
                                <h3 className="sec-title text-center">Register with OTP</h3>
                                <div className="register-form">
                                    <div className="row">
                                        <div className="col-lg-9 mar-lef-auto">
                                            <OTPInput otp={otp} setOTP={setOTP} otpLength={4} />
                                        </div>
                                        <div className="col-lg-9 mar-lef-auto">
                                            <input type="text" name="name" placeholder="Name" />
                                        </div>
                                        <div className="col-lg-9 mar-lef-auto">
                                            <input type="number" name="phone" placeholder="Phone" />
                                        </div>
                                        <div className="col-lg-9 mar-lef-auto">
                                            <input type="email" name="email" placeholder="Email" />
                                        </div>
                                    </div>
                                    <button type="button" className='submit-button'>Register</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default Login;
