import { IonContent, IonHeader, IonPage, IonRouterLink, useIonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Configs, PageLinks } from '../common/Constants';
import { APIData } from '../common/DataTypes';
import * as Utilities from '../common/Utilities';
import * as ServerAPI from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';
import { useHistory, useLocation } from 'react-router';

import { Helmet } from 'react-helmet';

const Cart: React.FC = () => {

    const location = useLocation();
    const history = useHistory();
    const [presentAlert] = useIonAlert();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests, setCartCount } = useAppStateAPI();
    const [searchParams, setSearchParams] = useState<URLSearchParams>();
    const [searchParamsSet, setSearchParamsSet] = useState(false);

    const [cartDetail, setCartDetail] = useState<APIData.CartDetail>();

    useEffect(() => {
        if (location.pathname == PageLinks.CART) {
            fetchCartData();
        }
    }, [location.pathname]);

    useEffect(() => {
        setSearchParams(new URLSearchParams(location.search));
    }, [location.search]);

    useEffect(() => {
        if (searchParams) {
            setSearchParamsSet(true);
        }
    }, [searchParams]);

    useEffect(() => {
        if (searchParamsSet && searchParams) {
            const variantID = searchParams.get('product_variant_id');
            const length = searchParams.get('length');
            const breadth = searchParams.get('breadth');
            const height = searchParams.get('height');
            const quantity = searchParams.get('quantity');
            const buyNow = searchParams.get('buy_now');
            if (variantID && Utilities.isStringValidNumber(variantID) && length && Utilities.isStringValidNumber(length) && breadth && Utilities.isStringValidNumber(breadth) && height && Utilities.isStringValidNumber(height) && quantity && Utilities.isStringValidNumber(quantity)) {
                addProcessingRequests();
                ServerAPI.addCustomizedItemToCart(parseInt(variantID), parseInt(quantity), parseFloat(length), parseFloat(breadth), parseFloat(height)).then((response) => {
                    if (response?.success) {
                        if (response.cart_count !== undefined) {
                            setCartCount(response.cart_count);
                        }
                        fetchCartData();
                        if (buyNow) {
                            history.replace(PageLinks.CHECKOUT);
                        } else {
                            history.replace(PageLinks.CART);
                        }
                        showToastMsg('success', response?.message);
                    } else {
                        showToastMsg('error', 'Could not add item to cart');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            } else {
                if (variantID || quantity || length || breadth || height) {
                    showToastMsg('error', 'Invalid request parameters');
                    history.replace(PageLinks.CART);
                }
            }
            setSearchParamsSet(false);
        }
    }, [searchParamsSet]);

    const fetchCartData = () => {
        addProcessingRequests();
        ServerAPI.getCartItems().then((response) => {
            if (response?.success) {
                setCartDetail({ cart_summary: response.cart_summary, cart_items: response.cart_items });
            } else if (response?.message) {
                showToastMsg('error', response?.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const removeCartItem = (cartItemID: number) => {
        addProcessingRequests();
        ServerAPI.removeItemFromCart(cartItemID).then((response) => {
            if (response?.success) {
                setCartCount(response.cart_count);
                fetchCartData();
            } else if (response?.message) {
                showToastMsg('error', response?.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const updateCartItemQuantity = (cartItemID: number, quantity: number) => {
        addProcessingRequests();
        ServerAPI.updateCartItem(cartItemID, quantity).then((response) => {
            if (response?.success) {
                setCartCount(response.cart_count);
                fetchCartData();
            } else if (response?.message) {
                showToastMsg('error', response?.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Cart</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/cart' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ' />
                <meta property='og:description' content='VENETZ' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">Cart</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Cart</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="cart-section">
                        <div className="container-fluid">
                            {cartDetail !== undefined && cartDetail.cart_items.length === 0 && (
                                <div className="row text-center" id="cartIsEmpty">
                                    <div className="page-404 shopping-cart left orders col-lg-12">
                                        <h2 className="mb-50">Oops ! Cart is empty.</h2>
                                        <div className="text">Your shopping cart is empty.. <IonRouterLink routerLink={PageLinks.HOME}>Click here</IonRouterLink> to continue shopping.</div>
                                    </div>
                                </div>
                            )}
                            {cartDetail !== undefined && cartDetail.cart_items.length > 0 && (
                                <div className="row">
                                    <div className="col-md-12 table-responsive">
                                        <form className="woocommerce-cart-form" action="#">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <table className="cart-table">
                                                        <thead>
                                                            <tr>
                                                                <th className="product-name-thumbnail">Product Name</th>
                                                                <th className="product-price">Unit Price</th>
                                                                <th className="product-quantity">Quantity</th>
                                                                <th className="product-total">Total</th>
                                                                <th className="product-remove">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {cartDetail.cart_items.map((cartItem, index) => {
                                                                const itemPrice = cartItem.cart_item_customized ? cartItem.cart_item_custom_price : cartItem.product_variant_selling_price;
                                                                return (
                                                                    <tr className="cart-item" key={index}>
                                                                        {cartItem.cart_item_customized ?
                                                                            <td className="product-thumbnail-title">
                                                                                <a href="#" onClick={e => e.preventDefault()} className='cart_pro_img'>
                                                                                    <img src={cartItem.product_image} alt={cartItem.product_image_caption} />
                                                                                </a>
                                                                                <a className="product-name" href="#" onClick={e => e.preventDefault()}>{cartItem.cart_item_custom_title}</a>
                                                                                <br /><small>{cartItem.cart_item_custom_description}</small>
                                                                                <br /><small>
                                                                                    {cartItem.attribute_values !== undefined && cartItem.attribute_values.map((attributeValue, attrIdx) => {
                                                                                        return <div key={attrIdx}>{attributeValue.attribute_type_name}: {attributeValue.attribute_value_text}</div>
                                                                                    })}
                                                                                </small>
                                                                            </td>
                                                                            :
                                                                            <td className="product-thumbnail-title">
                                                                                <IonRouterLink routerLink={PageLinks.PRODUCT_DETAILS + '/' + cartItem.product_url_name} className='cart_pro_img'>
                                                                                    <img src={cartItem.product_image} alt={cartItem.product_image_caption} />
                                                                                </IonRouterLink>
                                                                                <IonRouterLink className="product-name" routerLink={PageLinks.PRODUCT_DETAILS + '/' + cartItem.product_url_name}>{cartItem.product_name}</IonRouterLink>
                                                                                <br /><small>
                                                                                    {cartItem.attribute_values !== undefined && cartItem.attribute_values.map((attributeValue, attrIdx) => {
                                                                                        return <div key={attrIdx}>{attributeValue.attribute_type_name}: {attributeValue.attribute_value_text}</div>
                                                                                    })}
                                                                                </small>
                                                                            </td>
                                                                        }
                                                                        <td className="product-unit-price">
                                                                            <div className="product-price clearfix">
                                                                                <span className="price">
                                                                                    {parseFloat(itemPrice) > 0 && (
                                                                                        <span><span className="woocommerce-Price-currencySymbol">₹</span>{Utilities.formatIndianNumber(parseFloat(itemPrice))}</span>
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="product-quantity clearfix">
                                                                            <div className="quantityd clearfix">
                                                                                {cartItem.addon_main_cart_item_id === 0 && (
                                                                                    <button type='button' className="qtyBtn btnMinus" onClick={() => updateCartItemQuantity(cartItem.cart_item_id, cartItem.cart_item_quantity - 1)} disabled={cartItem.cart_item_quantity === 1}><span>-</span></button>
                                                                                )}
                                                                                <input name="qty" value={cartItem.cart_item_quantity} title="Qty" className="input-text qty text carqty" type="text" disabled />
                                                                                {cartItem.addon_main_cart_item_id === 0 && (
                                                                                    <button type='button' className="qtyBtn btnPlus" onClick={() => updateCartItemQuantity(cartItem.cart_item_id, cartItem.cart_item_quantity + 1)} disabled={cartItem.cart_item_quantity === 10}>+</button>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                        <td className="product-total">
                                                                            <div className="product-price clearfix">
                                                                                <span className="price">
                                                                                    {cartItem.item_amount > 0 && (
                                                                                        <span><span className="woocommerce-Price-currencySymbol">₹</span>{Utilities.formatIndianNumber(cartItem.item_amount)}</span>
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="product-remove">
                                                                            {cartItem.addon_main_cart_item_id === 0 && (
                                                                                <button type="button" onClick={() => {
                                                                                    presentAlert({
                                                                                        header: 'Remove from Cart',
                                                                                        message: 'Are you sure you want to remove this item from the cart?',
                                                                                        buttons: [{ text: 'OK', role: 'confirm' }, { text: 'Cancel', role: 'cancel' }],
                                                                                        onDidDismiss: ({ detail }) => {
                                                                                            if (detail.role === 'confirm') {
                                                                                                removeCartItem(cartItem.cart_item_id);
                                                                                            }
                                                                                        }
                                                                                    });
                                                                                }} />
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            <tr>
                                                                <td colSpan={6} className="actions">
                                                                    <IonRouterLink routerLink={PageLinks.HOME}>
                                                                        <button type="submit" className="button continue-shopping">Continue Shopping</button>
                                                                    </IonRouterLink>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    {/* <div className="coupon">
                                                    <h3>Coupon discount</h3>
                                                    <input type="text" name="coupon_code" placeholder="Enter Your code Here" />
                                                    <button type="submit" className="button" name="apply_coupon">Apply coupon</button>
                                                </div> */}
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="cart-totals">
                                                        <h2>Cart Summary</h2>
                                                        <table className="shop-table">
                                                            <tbody>
                                                                <tr className="cart-subtotal">
                                                                    <th>Items</th>
                                                                    <td data-title="Subtotal">
                                                                        <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">₹</span>{cartDetail.cart_summary.item_total}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr className="cart-subtotal">
                                                                    <th>Delivery</th>
                                                                    <td data-title="Subtotal">
                                                                        <span className="woocommerce-Price-amount amount">
                                                                            {/* <div className=' shipping_dis_amt'><del><span className="woocommerce-Price-currencySymbol">₹</span>800</del></div> */}
                                                                            <span className="woocommerce-Price-currencySymbol">₹</span>
                                                                            {/* cartDetail.cart_summary.shipping_total */}
                                                                            {Configs.DeliveryAmount}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                {/* {cartDetail.cart_summary.discount > 0 && (
                                                                <tr className="cart-subtotal">
                                                                    <th>Discount</th>
                                                                    <td data-title="Subtotal">
                                                                        <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">₹</span>{cartDetail.cart_summary.discount}</span>
                                                                    </td>
                                                                </tr>
                                                            )} */}
                                                                <tr className="cart-subtotal">
                                                                    <th>Total</th>
                                                                    <td data-title="Subtotal">
                                                                        <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">₹</span>{cartDetail.cart_summary.item_total + Configs.DeliveryAmount}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr className="cart-subtotal">
                                                                    <th>Promotion Applied</th>
                                                                    <td data-title="Subtotal">
                                                                        <span className="woocommerce-Price-amount shipping amount"><span className="woocommerce-Price-currencySymbol">- ₹</span>{Configs.DeliveryAmount}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr className="order-total">
                                                                    <th>Order Total</th>
                                                                    <td data-title="Subtotal">
                                                                        <span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">₹</span>{cartDetail.cart_summary.total_payable}</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className='cart_dis_info'>
                                                            <p>Your Savings : <span className="woocommerce-Price-currencySymbol">₹</span> 800</p>
                                                            <ul>
                                                                <li>FREE Delivery</li>
                                                                <li>Item Discount</li>
                                                            </ul>
                                                        </div>
                                                        <div className="wc-proceed-to-checkout">
                                                            <IonRouterLink routerLink={PageLinks.CHECKOUT} className="checkout-button">Proceed to checkout</IonRouterLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default Cart;
