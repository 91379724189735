import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MyAccountLeftSidebar from './MyAccountLeftSidebar';
import { PageLinks } from '../common/Constants';
import { APIData } from '../common/DataTypes';
import * as ServerAPI from '../common/ServerAPI';
import * as Utilities from '../common/Utilities';
import { useAppStateAPI } from '../common/AppStateAPI';
import { useLocation } from 'react-router';

import { Helmet } from 'react-helmet';

const MyProfile: React.FC = () => {

    const location = useLocation();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();

    const [customerFirstName, setCustomerFirstName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [customerMobile, setCustomerMobile] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');

    useEffect(() => {
        if (location.pathname === PageLinks.MY_PROFILE) {
            fetchProfile();
        }
    }, [location.pathname]);

    const fetchProfile = () => {
        addProcessingRequests();
        ServerAPI.getCustomerProfile().then((response) => {
            if (response?.success && response.customers.length > 0) {
                const customer: APIData.CustomerProfile = response.customers[0];
                setCustomerFirstName(customer.customer_first_name || '');
                setCustomerLastName(customer.customer_last_name || '');
                setCustomerMobile(customer.customer_mobile || '');
                setCustomerEmail(customer.customer_email || '');
            } else if (response?.message) {
                showToastMsg('error', response?.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const saveCustomerProfile = () => {
        if (!customerFirstName) {
            showToastMsg('error', 'Please enter first name');
        } else if (!customerLastName) {
            showToastMsg('error', 'Please enter last name');
        } else if (!customerMobile) {
            showToastMsg('error', 'Please enter phone number');
        } else if (!Utilities.isValidIndianMobileNumber(customerMobile)) {
            showToastMsg('error', 'Please enter a valid phone number');
        } else if (!customerEmail) {
            showToastMsg('error', 'Please enter email');
        } else if (!Utilities.isValidEmail(customerEmail)) {
            showToastMsg('error', 'Please enter valid email');
        } else {
            addProcessingRequests();
            ServerAPI.updateCustomerProfile(customerFirstName, customerLastName, customerEmail, customerMobile).then((response) => {
                if (response?.success) {
                    showToastMsg('info', response?.message);
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | My Profile</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/my-profile' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | My Profile' />
                <meta property='og:description' content='VENETZ | My Profile' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">My Account</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / My Account / My Profile</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="blog-section pt-5">
                        <div className="container-fluid">
                            <div className="row">
                                <MyAccountLeftSidebar />
                                <div className="col-lg-10 col-md-9">
                                    <h3 className="sec-title">My Profile</h3>
                                    <div className="register-form">
                                        <form action="#" method="post">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <input type="text" placeholder="First Name" value={customerFirstName} onChange={(e) => setCustomerFirstName(e.target.value)} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <input type="text" placeholder="Last Name" value={customerLastName} onChange={(e) => setCustomerLastName(e.target.value)} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <input type="email" placeholder="Email" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <input type="text" placeholder="Mobile" value={customerMobile} onChange={(e) => setCustomerMobile(e.target.value)} />
                                                </div>
                                            </div>
                                            <input type="button" value="Save" onClick={saveCustomerProfile} />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default MyProfile;
