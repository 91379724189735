import axios from 'axios';
import { toast } from "react-hot-toast";
import * as Utilities from "./Utilities";
import { Configs, EndPoints } from "./Constants";

export enum APIMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export const InitAnonymousUser = async (fcmID: string) => {
    const data: { [k: string]: string | number } = {};
    data["fcm_id"] = fcmID;
    return executeAPI(EndPoints.InitAnonymousUser, APIMethod.POST, false, data);
}

export const getHome = async () => {
    return executeAPI(EndPoints.Home, APIMethod.GET, true);
}

export const getMenuCategories = async () => {
    return executeAPI(EndPoints.MenuCategories, APIMethod.GET, true);
}

export const getCountries = async () => {
    return executeAPI(EndPoints.Countries, APIMethod.GET, true);
}

export const getStates = async (countryID: number) => {
    const params: { [k: string]: string | number } = {};
    params["country_id"] = countryID;
    return executeAPI(EndPoints.States, APIMethod.GET, true, null, params);
}

export const getCartCount = async () => {
    return executeAPI(EndPoints.CartCount, APIMethod.GET, true);
}

export const getProductByURL = async (urlName: string, addOn?: boolean) => {
    const params: { [k: string]: string | number } = {};
    params["product_url_name"] = urlName;
    params["detailed"] = 1;
    if (addOn) {
        params["addon"] = 1;
    }

    return executeAPI(EndPoints.Products, APIMethod.GET, true, null, params);
}

export const getProductList = async (categoryURLName: string) => {
    const params: { [k: string]: string | number } = {};
    params["category_url_name"] = categoryURLName;

    return executeAPI(EndPoints.Products, APIMethod.GET, true, null, params);
}

export const getCustomizationProductDetail = async () => {
    const params: { [k: string]: string | number } = {};
    params["customize"] = 1;
    params["detailed"] = 1;

    return executeAPI(EndPoints.Products, APIMethod.GET, true, null, params);
}

export const getProductCustomSize = async (length: number, breadth: number, height: number) => {
    const params: { [k: string]: string | number } = {};
    params["length"] = length;
    params["breadth"] = breadth;
    params["height"] = height;

    return executeAPI(EndPoints.ProductCustomSize, APIMethod.GET, true, null, params);
}

export const getHighlightItems = async (higlightTypeID: number) => {
    const params: { [k: string]: string | number } = {};
    params["highlight_type_id"] = higlightTypeID;

    return executeAPI(EndPoints.HighlightItems, APIMethod.GET, true, null, params);
}

export const addItemToCart = async (productVariantID: number, quantity: number, addonProductVariantIDs?: number[]) => {
    const data: { [k: string]: string | number } = {};
    data["product_variant_id"] = productVariantID;
    data["cart_item_quantity"] = quantity;
    if (addonProductVariantIDs && addonProductVariantIDs.length > 0) {
        data["addon_product_variant_ids"] = addonProductVariantIDs.join(",");
    }
    return executeAPI(EndPoints.CartItems, APIMethod.POST, true, data);
}

export const addItemToCartWithAddon = async (productVariantIDs: number[], quantity: number) => {
    const data: { [k: string]: string | number } = {};
    data["product_variant_ids"] = productVariantIDs.join(",");
    data["quantity"] = quantity;
    return executeAPI(EndPoints.AddCartItemAddon, APIMethod.POST, true, data);
}

export const addCustomizedItemToCart = async (productVariantID: number, quantity: number, length: number, breadth: number, height: number) => {
    const data: { [k: string]: string | number } = {};
    data["product_variant_id"] = productVariantID;
    data["cart_item_quantity"] = quantity;
    data["cart_item_customized"] = 1;
    data["cart_item_custom_param1"] = length;
    data["cart_item_custom_param2"] = breadth;
    data["cart_item_custom_param3"] = height;
    return executeAPI(EndPoints.CartItems, APIMethod.POST, true, data);
}

export const getCartItems = async (paymentMethodTerm?: number) => {
    const params: { [k: string]: string | number } = {};
    if (paymentMethodTerm) {
        params['payment_method_term'] = paymentMethodTerm;
    }
    return executeAPI(EndPoints.CartItems, APIMethod.GET, true, null, params);
}

export const addItemToWishlist = async (productID: number) => {
    const data: { [k: string]: string | number } = {};
    data["product_id"] = productID;
    return executeAPI(EndPoints.WishlistItems, APIMethod.POST, true, data);
}

export const removeItemFromWishlist = async (wishlistItemID: number) => {
    return executeAPI(EndPoints.WishlistItems + '/' + wishlistItemID, APIMethod.DELETE, true);
}

export const getWishlistItems = async () => {
    return executeAPI(EndPoints.WishlistItems, APIMethod.GET, true);
}

export const updateCartItem = async (cartItemID: number, quantity: number) => {
    const data: { [k: string]: string | number } = {};
    data["cart_item_quantity"] = quantity;
    return executeAPI(EndPoints.CartItems + '/' + cartItemID, APIMethod.PUT, true, data);
}

export const removeItemFromCart = async (cartItemID: number) => {
    return executeAPI(EndPoints.CartItems + '/' + cartItemID, APIMethod.DELETE, true);
}

export const customerLoginSendOTP = async (mobile: string) => {
    const data: { [k: string]: string | number } = {};
    data["username"] = mobile;
    return executeAPI(EndPoints.CustomerLogin, APIMethod.POST, true, data);
}

export const customerLoginVerifyOTP = async (mobile: string, otp: string) => {
    const data: { [k: string]: string | number } = {};
    data["username"] = mobile;
    data["otp"] = otp;
    return executeAPI(EndPoints.CustomerLogin, APIMethod.POST, true, data);
}

export const customerRegister = async (firstName: string, lastName: string, email: string, phone: string, otp: string) => {
    const data: { [k: string]: string | number } = {};
    data["customer_first_name"] = firstName;
    data["customer_last_name"] = lastName;
    data["customer_mobile"] = phone;
    data["customer_email"] = email;
    data["otp"] = otp;
    return executeAPI(EndPoints.CustomerRegister, APIMethod.POST, true, data);
}

export const getCustomerAddresses = async () => {
    return executeAPI(EndPoints.CustomerAddresses, APIMethod.GET, true);
}

export const getCustomerAddress = async (addressID: number) => {
    return executeAPI(EndPoints.CustomerAddresses + '/' + addressID, APIMethod.GET, true);
}

export const addCustomerAddress = async (fullName: string, phone: string, line1: string, line2: string, landmark: string, city: string, stateID: number, postalCode: string, companyName: string, remarks: string, gstin: string) => {
    const data: { [k: string]: string | number } = {};
    data["customer_address_full_name"] = fullName;
    data["customer_address_phone"] = phone;
    data["customer_address_line1"] = line1;
    data["customer_address_line2"] = line2;
    data["customer_address_landmark"] = landmark;
    data["customer_address_city"] = city;
    data["customer_address_state_id"] = stateID;
    data["customer_address_postal_code"] = postalCode;
    data["customer_address_company_name"] = companyName;
    data["customer_address_remarks"] = remarks;
    data["customer_address_gstin"] = gstin;
    return executeAPI(EndPoints.CustomerAddresses, APIMethod.POST, true, data);
}

export const updateCustomerAddress = async (addressID: number, fullName: string, phone: string, line1: string, line2: string, landmark: string, city: string, stateID: number, postalCode: string, companyName: string, remarks: string, gstin: string) => {
    const data: { [k: string]: string | number } = {};
    data["customer_address_full_name"] = fullName;
    data["customer_address_phone"] = phone;
    data["customer_address_line1"] = line1;
    data["customer_address_line2"] = line2;
    data["customer_address_landmark"] = landmark;
    data["customer_address_city"] = city;
    data["customer_address_state_id"] = stateID;
    data["customer_address_postal_code"] = postalCode;
    data["customer_address_company_name"] = companyName;
    data["customer_address_remarks"] = remarks;
    data["customer_address_gstin"] = gstin;
    return executeAPI(EndPoints.CustomerAddresses + '/' + addressID, APIMethod.PUT, true, data);
}

export const deleteCustomerAddress = (addressID: number) => {
    return executeAPI(EndPoints.CustomerAddresses + '/' + addressID, APIMethod.DELETE, true);
}

export const getPaymentMethods = () => {
    return executeAPI(EndPoints.PaymentMethods, APIMethod.GET, true);
}

export const checkoutOrder = async (billAddressID: number, paymentMethod: number) => {
    const data: { [k: string]: string | number } = {};
    data["bill_address_id"] = billAddressID;
    data["payment_method"] = paymentMethod;

    return executeAPI(EndPoints.Checkout, APIMethod.POST, true, data);
}

export const getOrderGroupDetail = async (orderGroupID: number) => {
    const params: { [k: string]: string | number } = {};
    params["detailed"] = 1;

    return executeAPI(EndPoints.OrderGroups + '/' + orderGroupID, APIMethod.GET, true, null, params);
}

export const getOrderDetail = async (orderID: number) => {
    const params: { [k: string]: string | number } = {};
    params["detailed"] = 1;

    return executeAPI(EndPoints.Orders + '/' + orderID, APIMethod.GET, true, null, params);
}

export const getOrders = async () => {
    return executeAPI(EndPoints.Orders, APIMethod.GET, true);
}

export const getCustomerProfile = async () => {
    return executeAPI(EndPoints.CustomerProfile, APIMethod.GET, true);
}

export const updateCustomerProfile = async (firstName: string, lastName: string, email: string, phone: string) => {
    const data: { [k: string]: string | number } = {};
    data["customer_first_name"] = firstName;
    data["customer_last_name"] = lastName;
    data["customer_mobile"] = phone;
    data["customer_email"] = email;
    return executeAPI(EndPoints.CustomerProfile, APIMethod.POST, true, data);
}

export const updateCustomerPassword = async (oldPassword: string, newPassword: string) => {
    const data: { [k: string]: string | number } = {};
    data["current_password"] = oldPassword;
    data["new_password"] = newPassword;
    return executeAPI(EndPoints.CustomerPasswordUpdate, APIMethod.POST, true, data);
}

export const getSearchSuggestions = async (searchTerm: string) => {
    const params: { [k: string]: string | number } = {};
    params["search"] = searchTerm;
    return executeAPI(EndPoints.SearchSuggestions, APIMethod.GET, true, null, params);
}

export const sendContactEnquiry = async (name: string, email: string, subject: string, message: string) => {
    const data: { [k: string]: string | number } = {};
    data["contact_name"] = name;
    data["contact_email"] = email;
    data["subject"] = subject;
    data["message"] = message;
    return executeAPI(EndPoints.ContactUs, APIMethod.POST, true, data);
}

export const getTestimonials = async () => {
    return executeAPI(EndPoints.Testimonials, APIMethod.GET, true);
}

export const deliveryCheck = async (postalCode: string, cod?: boolean) => {
    const params: { [k: string]: string | number } = {};
    params["postal_code"] = postalCode;
    if (cod) {
        params["cod"] = 1;
    }
    return executeAPI(EndPoints.DeliveryCheck, APIMethod.GET, true, null, params);
}







export const executeAPI = async (
    endpoint: string,
    method: APIMethod,
    authRequired?: boolean,
    data?: any,
    params?: { [k: string]: string | number }
) => {
    const persistAuthString = await Utilities.getAuthString();
    const headers: { [k: string]: string } = {};

    if (persistAuthString && authRequired) {
        headers["Authorization"] = persistAuthString;
    }
    if (method === APIMethod.POST || method === APIMethod.PUT) {
        headers["Content-Type"] = "application/x-www-form-urlencoded";
    }

    try {
        const response = await axios({
            method: method,
            url: Configs.BaseUrl + endpoint,
            data: JSON.stringify(data),
            headers: headers,
            params: params,
        });
        if (response.status == 201 || response.status == 200) {
            console.log(response, "new user");
            return response.data;
        } else {
            if (response.data.message) {
                toast.error(response.data.message);
            }
            console.log("Error", response);
        }
    } catch (error: any) {
        console.log("error", error);
        if (error.response && error.response.data) {
            toast.error(error.response.data);
        } else if (error.message) {
            toast.error(error.message);
        } else {
            toast.error("Error occurred while processing the request");
        }
    }
}