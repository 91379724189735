import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Configs, PageLinks, PaymentMethods, PaymentTerm } from '../common/Constants';
import { useAppStateAPI } from '../common/AppStateAPI';
import { APIData } from '../common/DataTypes';
import * as Utilities from '../common/Utilities';
import * as ServerAPI from '../common/ServerAPI';
import AddressEntry from '../components/modals/AddressEntry';
import LoginCard from '../components/login/LoginCard';
import { useHistory } from 'react-router';
import { Modal, Button } from "react-bootstrap";
import { Helmet } from 'react-helmet';

// declare let bolt: any;


const Checkout: React.FC = () => {

    const history = useHistory();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests, customerLogin, setCustomerLoginRedirectLink } = useAppStateAPI();

    const [pageInit, setPageInit] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [editAddressID, setEditAddressID] = useState(0);
    const [cartDetail, setCartDetail] = useState<APIData.CartDetail>();
    const [addresses, setAddresses] = useState<APIData.Address[]>([]);
    const [billAddressID, setBillAddressID] = useState(0);
    const [paymentMethods, setPaymentMethods] = useState<APIData.PaymentMethod[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<APIData.PaymentMethod>();
    const [selectedPaymentMethodID, setSelectedPaymentMethodID] = useState(0);
    const [deliveryAvailable, setDeliveryAvailable] = useState<'available' | 'unavailable' | ''>('');

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (cartDetail?.cart_summary?.cod_charges && cartDetail.cart_summary.cod_charges > 0) {
            setShowModal(true);
        }
    }, [cartDetail]);


    useEffect(() => {
        if (!customerLogin) {
            setCustomerLoginRedirectLink(PageLinks.CHECKOUT);
            history.replace(PageLinks.LOGIN);
        } else {
            setPageInit(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (pageInit && location.pathname == PageLinks.CHECKOUT) {
            fetchCartData();
            fetchAddresses();
            fetchPaymentMethods();
        }
    }, [pageInit, location.pathname]);

    useEffect(() => {
        const selectedMethods = paymentMethods.filter(paymentMethod => paymentMethod.payment_method_id === selectedPaymentMethodID);
        if (selectedMethods.length > 0) {
            setSelectedPaymentMethod(selectedMethods[0]);
        } else {
            setSelectedPaymentMethod(undefined);
        }
    }, [selectedPaymentMethodID]);

    useEffect(() => {
        fetchCartData();
    }, [selectedPaymentMethod]);

    const fetchCartData = () => {
        addProcessingRequests();
        const paymentMethodTerm = selectedPaymentMethod !== undefined ? selectedPaymentMethod.payment_method_term : undefined;
        ServerAPI.getCartItems(paymentMethodTerm).then((response) => {
            if (response?.success) {
                setCartDetail({ cart_summary: response.cart_summary, cart_items: response.cart_items });
            } else if (response?.message) {
                showToastMsg('error', response?.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const fetchAddresses = () => {
        if (customerLogin) {
            addProcessingRequests();
            ServerAPI.getCustomerAddresses().then((response) => {
                if (response?.success) {
                    setAddresses(response.customer_addresses);
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    const fetchPaymentMethods = () => {
        addProcessingRequests();
        ServerAPI.getPaymentMethods().then((response) => {
            if (response?.success) {
                const payMethods: APIData.PaymentMethod[] = response.payment_methods

                setPaymentMethods(payMethods);
                payMethods.forEach((paymentMethod) => {
                    if (paymentMethod.payment_method_default_selection) {
                        setSelectedPaymentMethodID(paymentMethod.payment_method_id);

                    }
                })
            } else if (response?.message) {
                showToastMsg('error', response?.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const checkDeliveryAvailability = () => {
        if (!billAddressID) {
            showToastMsg('error', 'Select Address');
        } else if (!selectedPaymentMethodID) {
            showToastMsg('error', 'Select Payment Method');
        } else {
            const selectedAddress = addresses.filter(address => address.customer_address_id === billAddressID);
            const selPaymentMethod = paymentMethods.filter(paymentMethod => paymentMethod.payment_method_id === selectedPaymentMethodID);
            if (selectedAddress.length > 0 && selPaymentMethod.length > 0) {
                addProcessingRequests();
                ServerAPI.deliveryCheck(selectedAddress[0].customer_address_postal_code, selPaymentMethod[0].payment_method_term === PaymentTerm.Postpaid ? true : false).then((response) => {
                    if (response?.success) {
                        if (response.delivery_available) {
                            setDeliveryAvailable('available');
                        } else {
                            setDeliveryAvailable('unavailable');
                        }
                    } else if (response?.message) {
                        showToastMsg('error', response?.message);
                    } else {
                        showToastMsg('error', 'Error occurred while processing the request');
                    }
                }).finally(() => {
                    reduceProcessingRequests();
                });
            }
        }
    }

    useEffect(() => {
        setDeliveryAvailable('');
        if (addresses.length === 0) {
            setBillAddressID(0);
        }
        if (billAddressID && selectedPaymentMethodID) {
            checkDeliveryAvailability();
        }
    }, [addresses, billAddressID, selectedPaymentMethodID]);

    const checkout = () => {
        if (!billAddressID) {
            showToastMsg('error', 'Select the Billing Address');
        } else if (!selectedPaymentMethodID) {
            showToastMsg('error', 'Please select the Method of Payment');
        } else {
            addProcessingRequests();
            ServerAPI.checkoutOrder(billAddressID, selectedPaymentMethodID).then((response) => {
                if (response?.success) {
                    showToastMsg('success', response?.message);
                    if (selectedPaymentMethodID === PaymentMethods.COD) {
                        history.replace(PageLinks.CHECKOUT_RESPONSE + "/" + response.order_group_id);
                    } else if (selectedPaymentMethodID === PaymentMethods.PayU && response.checkout_data) {
                        const payUData = response.checkout_data;
                        payUData.surl = window.location.origin + PageLinks.CHECKOUT_RESPONSE + "/" + response.order_group_id;
                        payUData.furl = window.location.origin + PageLinks.CHECKOUT_RESPONSE + "/" + response.order_group_id;
                        payUData.curl = window.location.origin + PageLinks.CHECKOUT;
                        submitPayUForm(response.checkout_url, payUData);
                    }
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                reduceProcessingRequests();
            });
        }
    }

    function submitPayUForm(url: string, data: any) {
        // Create a form element
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = url;

        // Iterate through the JSON object
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                // Create hidden fields for each property in the JSON object
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = data[key];

                // Append the hidden field to the form
                form.appendChild(hiddenField);
            }
        }

        // Append the form to the body (this is necessary for the form to be submitted)
        document.body.appendChild(form);

        // Submit the form
        form.submit();

        // Optionally: Remove the form from the body after submission
        document.body.removeChild(form);
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Checkout</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/checkout' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Checkout' />
                <meta property='og:description' content='VENETZ | Checkout' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">Checkout</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Checkout</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="checkout-section">
                        <div className="container-fluid">
                            {cartDetail !== undefined && cartDetail.cart_items.length === 0 && (
                                <div className="row text-center" id="cartIsEmpty">
                                    <div className="page-404 shopping-cart left orders col-lg-12">
                                        <h2 className="mb-50">Oops ! Cart is empty.</h2>
                                        <div className="text">Your shopping cart is empty.. <IonRouterLink routerLink={PageLinks.HOME}>Click here</IonRouterLink> to continue shopping.</div>
                                    </div>
                                </div>
                            )}
                            {cartDetail !== undefined && cartDetail.cart_items.length > 0 && (
                                <div className="row">
                                    <div className="col-md-7">
                                        {!customerLogin && (
                                            <div className="col-lg-12 col-md-12 mobile_mt-5">
                                                <p className="sec-desc mb-2">
                                                    Returning customer ? Login Here
                                                </p>
                                                <LoginCard />
                                            </div>
                                        )}
                                        <div className="woocommerce-billing-fields">
                                            <section className="error-section">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-8 offset-lg-2">
                                                            <div className="content-404 text-center">
                                                                {addresses.length === 0 && (
                                                                    <h2>No Address Found...</h2>
                                                                )}
                                                                <a className="goru-btn" onClick={() => {
                                                                    setEditAddressID(0);
                                                                    setShowAddressModal(true);
                                                                }}>Add Address</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div>
                                                <AddressEntry showModal={showAddressModal} setShowModal={setShowAddressModal} addressID={editAddressID} successCallBack={fetchAddresses} />
                                            </div>
                                            {addresses.length > 0 && (
                                                <>
                                                    <h3>Shipping & Billing Address</h3>
                                                    <div className="woocommerce-checkout-payment" id="myaddress">
                                                        <ul className="wc_payment_methods payment_methods methods">
                                                            {addresses.map((address, index) => (
                                                                <li className="wc_payment_method payment_method_bacs" key={index}>
                                                                    <input name="selected_address" className="input-radio" id={`selected_address_${address.customer_address_id}`} type="radio" checked={billAddressID === address.customer_address_id} onChange={() => setBillAddressID(address.customer_address_id)} />
                                                                    <label htmlFor={`selected_address_${address.customer_address_id}`}>{address.customer_address_full_name} <a onClick={() => {
                                                                        setEditAddressID(address.customer_address_id);
                                                                        setShowAddressModal(true);
                                                                    }}><i className='fa fa-edit'></i></a></label>
                                                                    <div className="payment_box payment_method_bacs visibales">
                                                                        {address.customer_address_line1 !== '' && (<p>{address.customer_address_line1},</p>)}
                                                                        {address.customer_address_line2 !== '' && (<p>{address.customer_address_line2},</p>)}
                                                                        <p>{address.customer_address_city}</p>
                                                                        <p>{address.state_name} - {address.customer_address_postal_code}</p>
                                                                        <p>Mobile : {address.customer_address_phone}</p>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="woocommerce-checkout-review-order" id="order_review">
                                            <h3>Your Order</h3>
                                            <table className="check-table woocommerce-checkout-review-order-table">
                                                <thead>
                                                    <tr>
                                                        <th className="product-name">Products</th>
                                                        <th className="product-total"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cartDetail.cart_items.map((cartItem, index) => {
                                                        const itemName = cartItem.cart_item_customized ? cartItem.cart_item_custom_title : cartItem.product_name;
                                                        const itemPrice = cartItem.cart_item_customized ? cartItem.cart_item_custom_price : cartItem.product_variant_selling_price;
                                                        return (
                                                            <tr className="cart-item" key={index}>
                                                                <td className="product-name">{itemName}{cartItem.cart_item_quantity > 1 ? ` (x${cartItem.cart_item_quantity})` : ''}</td>
                                                                <td className="product-total">
                                                                    <div className="product-price clearfix"> <span className="price">
                                                                        {parseFloat(itemPrice) > 0 && (
                                                                            <span><span className="woocommerce-Price-currencySymbol">₹</span>{Utilities.formatIndianNumber(parseFloat(itemPrice) * cartItem.cart_item_quantity)}</span>
                                                                        )}
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr className="cart-subtotal">
                                                        <th>Delivery</th>
                                                        <td>
                                                            <div className="product-price clearfix">
                                                                <span className="price woocommerce-Price-amount shipping amount">
                                                                    {/* <div className=' shipping_dis_amt'><del><span className="woocommerce-Price-currencySymbol">₹</span>800</del></div> */}
                                                                    <span className="woocommerce-Price-currencySymbol">₹</span>{Configs.DeliveryAmount}
                                                                    {/* {cartDetail.cart_summary.shipping_total} */}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    {cartDetail.cart_summary.cod_charges > 0 && (
                                                        <tr className="cart-subtotal">
                                                            <th>COD Charges</th>
                                                            <td>
                                                                <div className="product-price clearfix">
                                                                    <span className="price woocommerce-Price-amount shipping amount">
                                                                        {/* <div className=' shipping_dis_amt'><del><span className="woocommerce-Price-currencySymbol">₹</span>800</del></div> */}
                                                                        <span className="woocommerce-Price-currencySymbol">₹</span>{cartDetail.cart_summary.cod_charges}
                                                                        {/* {cartDetail.cart_summary.shipping_total} */}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr className="cart-subtotal">
                                                        <th>Subtotal</th>
                                                        <td>
                                                            <div className="product-price clearfix"> <span className="price">
                                                                <span><span className="woocommerce-Price-currencySymbol">₹</span>{Utilities.formatIndianNumber(cartDetail.cart_summary.total_payable + Configs.DeliveryAmount)}</span>
                                                            </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="cart-subtotal">
                                                        <th>Promotion Applied</th>
                                                        <td>
                                                            <div className="product-price clearfix">
                                                                <span className="price woocommerce-Price-amount shipping amount">
                                                                    -<span className="woocommerce-Price-currencySymbol">₹</span>{Configs.DeliveryAmount}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="order-total">
                                                        <th>Total</th>
                                                        <td>
                                                            <div className="product-price clearfix"> <span className="price">
                                                                <span><span className="woocommerce-Price-currencySymbol">₹</span>{Utilities.formatIndianNumber(cartDetail.cart_summary.total_payable)}</span>
                                                            </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                            <div className="woocommerce-checkout-payment" id="payment">
                                                <ul className="wc_payment_methods payment_methods methods">
                                                    {paymentMethods.map((paymentMethod, payIndex) => (
                                                        <li className="wc_payment_method payment_method_bacs" key={payIndex}>
                                                            <input className="input-radio" id={`payment_method_${paymentMethod.payment_method_id}`} type="radio" checked={selectedPaymentMethodID === paymentMethod.payment_method_id} onChange={() => setSelectedPaymentMethodID(paymentMethod.payment_method_id)} />
                                                            <label htmlFor={`payment_method_${paymentMethod.payment_method_id}`}>{paymentMethod.payment_method_name}</label>
                                                            <div className="payment_box payment_method_bacs visibales">
                                                                {paymentMethod.payment_method_term === PaymentTerm.Postpaid ?
                                                                    <p> Pay cash by delivery of your order. <br />COD Charges Extra Rs.250 <br />
                                                                        <a className="text-primary" onClick={() => setShowModal(true)}>
                                                                            Terms and Conditions
                                                                        </a></p>
                                                                    :
                                                                    <p> Pay by Credit/Debit card, Wallet. </p>
                                                                }
                                                            </div>
                                                        </li>
                                                    ))}
                                                    {/* <li className="wc_payment_method payment_method_bacs">
                                                    <input className="input-radio" id={`payment_method_${PaymentMethods.Postpaid}`} type="radio" checked={selectedPaymentMethod === PaymentMethods.Postpaid} onChange={() => setSelectedPaymentMethod(PaymentMethods.Postpaid)} />
                                                    <label htmlFor={`payment_method_${PaymentMethods.Postpaid}`}>Cash On Delivery (COD)</label>
                                                    <div className="payment_box payment_method_bacs visibales">
                                                        <p> Pay cash by delivery of your order. </p>
                                                    </div>
                                                </li>
                                                <li className="wc_payment_method payment_method_paypal">
                                                    <input className="input-radio" id={`payment_method_${PaymentMethods.Prepaid}`} type="radio" checked={selectedPaymentMethod === PaymentMethods.Prepaid} onChange={() => setSelectedPaymentMethod(PaymentMethods.Prepaid)} />
                                                    <label htmlFor={`payment_method_${PaymentMethods.Prepaid}`}>Online Payment <i className="twi-cc-mastercard"></i><i className="twi-cc-visa"></i><i className="twi-cc-paypal"></i><i className="twi-cc-discover"></i></label>
                                                    <div className="payment_box payment_method_paypal">
                                                        <p> Pay cash through your bank. </p>
                                                    </div>
                                                </li> */}
                                                </ul>
                                            </div>
                                            <div className="place-order">
                                                <button type="button" className="button" onClick={checkout} disabled={billAddressID === 0 || selectedPaymentMethodID === 0 || deliveryAvailable === 'unavailable'}>
                                                    {billAddressID === 0 && ('Select Address')}
                                                    {billAddressID !== 0 && selectedPaymentMethodID === 0 && ('Select Pay Mode')}
                                                    {billAddressID !== 0 && selectedPaymentMethodID !== 0 && ('Place Order')}
                                                </button>
                                            </div>
                                            {deliveryAvailable === 'unavailable' && (
                                                <div className='cod_avilability_text not_available'>
                                                    {selectedPaymentMethod?.payment_method_term === PaymentTerm.Postpaid && ('COD ')}
                                                    Delivery Not Available For Your Location
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Bootstrap Modal */}
                        <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                            <Modal.Header closeButton className='bg-success'>
                                <Modal.Title className="fw-bold text-light">Cash on Delivery (COD) Policy</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="fw-semibold">Ensuring a Smooth Experience</p>
                                <p>
                                    To provide a hassle-free shopping experience, all <strong>COD orders</strong> require an upfront payment of
                                    <strong className='text-success' style={{ fontSize: "16px" }}> ₹800</strong>. The remaining amount must be paid in cash at the time of delivery.
                                </p>

                                <div className="border rounded p-3 bg-light">
                                    <h5 className="text-dark fw-bold">Why is an Advance Payment Required?</h5>
                                    <p>Many COD orders are placed and later refused, leading to unnecessary shipping losses. This impacts product pricing and availability.</p>
                                    <p>By collecting a small upfront payment, we:</p>
                                    <ul className="list-unstyled">
                                        <li>✔️ Ensure only <strong>genuine orders</strong> are processed</li>
                                        <li>✔️ Reduce <strong>shipping losses</strong></li>
                                        <li>✔️ Maintain <strong>fair pricing</strong> and smooth deliveries</li>
                                    </ul>
                                </div>

                                <div className="mt-1 border rounded p-3 bg-white shadow-sm">
                                    <h5 className="fw-bold text-dark">Example Calculation:</h5>
                                    <table className="table table-sm border ">
                                        <tbody>
                                            <tr>
                                                <td className='p-2'>Product Price:</td>
                                                <td className="text-end fw-bold p-2">₹4,000</td>
                                            </tr>
                                            <tr>
                                                <td className='p-2'>COD Charge:</td>
                                                <td className="text-end fw-bold p-2">₹250</td>
                                            </tr>
                                            <tr className="table-warning">
                                                <td className='p-2'>Total Payable Amount:</td>
                                                <td className="text-end fw-bold p-2">₹4,250</td>
                                            </tr>
                                            <tr className="table-info">
                                                <td className='p-2'>Upfront Payment:</td>
                                                <td className="text-end fw-bold p-2">₹800</td>
                                            </tr>
                                            <tr className="table-success">
                                                <td className='p-2'>Balance Payable on Delivery:</td>
                                                <td className="text-end fw-bold p-2">₹3,450</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <p className="mt-3">
                                    <strong>
                                        Please pay your advance payment via UPI to this number or UPI Id:
                                        <span className="text-decoration-none text-primary ml-1">
                                            9443310856
                                        </span> or
                                        <span className="text-decoration-none text-primary ml-1">
                                            mahavela5-1@okicici
                                        </span> .
                                    </strong>
                                </p>

                                <p className="mt-3">
                                    <strong>After making the advance payment, please share the payment screenshot via WhatsApp at
                                        <a href="tel:9585520356" className="text-decoration-none text-primary"> 95855 20356</a>.</strong>
                                </p>

                                <p className="text-muted">We appreciate your cooperation! For any questions, feel free to reach out.</p>
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Cancel
                                </Button> */}
                                <Button variant="success" onClick={() => setShowModal(false)}>
                                    Agree & Proceed
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default Checkout;
