import { IonContent, IonHeader, IonPage, IonRouterLink, useIonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MyAccountLeftSidebar from './MyAccountLeftSidebar';
import { PageLinks } from '../common/Constants';
import { useLocation } from 'react-router';
import * as ServerAPI from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';
import { APIData } from '../common/DataTypes';

import { Helmet } from 'react-helmet';

const MyWishlist: React.FC = () => {

    const location = useLocation();
    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [presentAlert] = useIonAlert();

    const [wishlistItems, setWishlistItems] = useState<APIData.WishlistItem[]>([]);

    useEffect(() => {
        if (location.pathname == PageLinks.MY_WISHLIST) {
            fetchWishlist();
        }
    }, [location.pathname]);

    const fetchWishlist = () => {
        addProcessingRequests();
        ServerAPI.getWishlistItems().then((response) => {
            if (response?.success) {
                setWishlistItems(response.wishlist_items);
            } else if (response?.message) {
                showToastMsg('error', response?.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    const removeFromWishlist = (wishlistItemID: number) => {
        addProcessingRequests();
        ServerAPI.removeItemFromWishlist(wishlistItemID).then((response) => {
            if (response?.success) {
                fetchWishlist();
                showToastMsg('success', response?.message);
            } else if (response?.message) {
                showToastMsg('error', response?.message);
            } else {
                showToastMsg('error', 'Error occurred while processing the request');
            }
        }).finally(() => {
            reduceProcessingRequests();
        });
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | My Wishlist</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/my-wishlist' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | My Wishlist' />
                <meta property='og:description' content='VENETZ | My Wishlist' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    <section className="page-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <span className="round-shape"></span>
                                    <h2 className="banner-title">My Account</h2>
                                    <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / My Account / My Wishlist</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="blog-section pt-5">
                        <div className="container-fluid">
                            <div className="row">
                                <MyAccountLeftSidebar />
                                <div className="col-lg-9 col-md-7">
                                    <h3 className="sec-title">My Wishlist</h3>
                                    {wishlistItems.length === 0 && (
                                        <div className="row text-center" id="cartIsEmpty">
                                            <div className="page-404 shopping-cart left orders col-lg-12">
                                                <h2 className="mb-50">Oops ! Wishlist is empty.</h2>
                                                <div className="text">You Not added any Products.. <IonRouterLink routerLink={PageLinks.HOME}>Click here</IonRouterLink> to Add products.</div>
                                            </div>
                                        </div>
                                    )}
                                    {wishlistItems.length > 0 && (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <form className="woocommerce-wishlist-form" action="#">
                                                    <table className="wishlist-table">
                                                        <thead>
                                                            <tr>
                                                                <th className="product-name-thumbnail">Product Name</th>
                                                                <th className="product-price">Unit Price</th>
                                                                <th className="product-quantity">Availability</th>
                                                                <th className="product-total">&nbsp;</th>
                                                                <th className="product-remove">&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {wishlistItems.map((wishlistItem, index) => (
                                                                <tr className="wishlist-item" key={index}>
                                                                    <td className="product-thumbnail-title">
                                                                        <IonRouterLink routerLink={PageLinks.PRODUCT_DETAILS + '/' + wishlistItem.product_url_name}>
                                                                            <img className='wishlist_img' src={wishlistItem.product_image} alt={wishlistItem.product_image_caption} />
                                                                        </IonRouterLink>
                                                                        <IonRouterLink className="product-name" routerLink={PageLinks.PRODUCT_DETAILS + '/' + wishlistItem.product_url_name}>{wishlistItem.product_name}</IonRouterLink>
                                                                    </td>
                                                                    <td className="product-unit-price">
                                                                        <div className="product-price clearfix">
                                                                            <span className="price">
                                                                                <span><span className="woocommerce-Price-currencySymbol">₹</span>{wishlistItem.product_start_selling_price}</span>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="product-availity clearfix">
                                                                        In Stock
                                                                    </td>
                                                                    <td className="add-to-cart">
                                                                        <IonRouterLink className='wishlist_add' routerLink={PageLinks.PRODUCT_DETAILS + '/' + wishlistItem.product_url_name}>Add To Cart</IonRouterLink>
                                                                    </td>
                                                                    <td className="product-remove">
                                                                        <a href="#" id="present-alert" onClick={e => {
                                                                            e.preventDefault();
                                                                            presentAlert({
                                                                                header: 'Remove from Wishlist',
                                                                                message: 'Are you sure you want to remove this item from the wishlist?',
                                                                                buttons: [{ text: 'OK', role: 'confirm' }, { text: 'Cancel', role: 'cancel' }],
                                                                                onDidDismiss: ({ detail }) => {
                                                                                    if (detail.role === 'confirm') {
                                                                                        removeFromWishlist(wishlistItem.wishlist_item_id);
                                                                                    }
                                                                                }
                                                                            });
                                                                        }}></a>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    );
};
export default MyWishlist;
