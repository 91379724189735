import { IonContent, IonHeader, IonPage, IonRouterLink } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { OrderStatuses, PageLinks } from '../common/Constants';
import OrderDetailCard from '../components/orders/OrderDetailCard';
import { RouteComponentProps } from 'react-router';
import * as ServerAPI from '../common/ServerAPI';
import { useAppStateAPI } from '../common/AppStateAPI';
import { APIData } from '../common/DataTypes';

import { Helmet } from 'react-helmet';

const CheckoutResponse: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {

    const { showToastMsg, addProcessingRequests, reduceProcessingRequests } = useAppStateAPI();
    const [orderGroupID, setOrderGroupID] = useState(0);

    const [orderGroupDetail, setOrderGroupDetail] = useState<APIData.OrderGroupDetail>();
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [requestProcessed, setRequestProcessed] = useState(false);

    useEffect(() => {
        setOrderGroupID(parseInt(match.params.id));
    }, [match]);

    useEffect(() => {
        fetchOrderGroupDetail();
    }, [orderGroupID]);

    const fetchOrderGroupDetail = () => {
        if (orderGroupID) {
            addProcessingRequests();
            ServerAPI.getOrderGroupDetail(orderGroupID).then((response) => {
                if (response.order_groups.length > 0) {
                    const orderGroup: APIData.OrderGroupDetail = response.order_groups[0];
                    setOrderGroupDetail(orderGroup);
                    if (orderGroup.orders.length > 0) {
                        setOrderSuccess(orderGroup.orders[0].order_status > OrderStatuses.Pending ? true : false);
                    }
                } else if (response?.message) {
                    showToastMsg('error', response?.message);
                } else {
                    showToastMsg('error', 'Error occurred while processing the request');
                }
            }).finally(() => {
                setRequestProcessed(true);
                reduceProcessingRequests();
            });
        }
    }

    return (
        <>
            <Helmet>‍
                <title>Customized Ready to install Mosquito nets with frames | Checkout Response</title>
                <meta name='title' content='Customized Ready to install Mosquito nets with frames' />
                <meta property='og:url' content='https://www.venetz.in/checkout-response' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='VENETZ | Checkout Response' />
                <meta property='og:description' content='VENETZ | Checkout Response' />
                <meta property='og:site_name' content='VENETZ' />
            </Helmet>
            <IonPage>
                {/* <Menu /> */}
                <IonHeader>
                    <Header />
                </IonHeader>
                <IonContent fullscreen id="main-content">
                    {requestProcessed && (
                        <>
                            <section className="page-banner">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span className="round-shape"></span>
                                            <h2 className="banner-title">Order {orderSuccess ? 'Success' : 'Failed'}</h2>
                                            <div className="bread-crumb"><IonRouterLink routerLink={PageLinks.HOME} className='bread-crumb-text'>Home</IonRouterLink> / Checkout</div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="checkout-section">
                                {orderSuccess === true && orderGroupDetail !== undefined && orderGroupDetail.orders.length > 0 ?
                                    <div className="container-fluid">
                                        <h1>Order Placed Successfully!</h1>
                                        <div className='order_success_img'><img src='assets/images/order_success.gif' /></div>
                                        <OrderDetailCard orderDetail={orderGroupDetail.orders[0]} />
                                    </div>
                                    :
                                    <div className="container-fluid">
                                        <h1>Order Failed! Please try again</h1>
                                    </div>
                                }
                            </section>
                        </>
                    )}
                    <Footer />
                </IonContent>
            </IonPage>
        </>
    )
}

export default CheckoutResponse;